import { Table } from 'antd'
import React from 'react'
import { useQuery } from 'react-query'
import { TransactionTypeTag } from '../components/TransactionTypeTag'
import { fetchTransactions } from '../data/endpoints/acs'
import { ACSTransaction } from '../data/models'
import { useOrganizationContext } from '../states/OrganizationContextProvider'
import { formatDateTime } from '../utils-hooks/date-utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'

type Props = {
  profileId: string
}

export const TransactionTable: React.FC<Props> = ({ profileId }) => {
  const { parent } = useOrganizationContext()

  const {
    isLoading,
    isError,
    data: transactions,
  } = useQuery(['/acs-transactions/profile', profileId], () =>
    fetchTransactions(parent.entity_id, profileId),
  )
  useErrorToast(isError)

  const columns = [
    {
      title: 'Type',
      render: (t: ACSTransaction) => <TransactionTypeTag type={t.result} />,
    },
    {
      title: 'System',
      render: (t: ACSTransaction) => t.epi_name,
    },
    {
      title: 'Date',
      render: (t: ACSTransaction) => formatDateTime(t.event_at),
    },
  ]

  return (
    <Table
      dataSource={transactions}
      columns={columns}
      rowKey="acs_transaction_id"
      loading={isLoading}
      pagination={false}
      scroll={{
        x: 'max-content',
      }}
    />
  )
}
