import { Button, Table } from 'antd'
import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useOrganizationContext } from '../states/OrganizationContextProvider'
import { getFullName } from '../utils-hooks/profile-utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'
import { fetchProfiles } from '../data/endpoints/ums'
import { Profile } from '../data/models'
import { LIMIT } from '../screens/ProfilesListScreenWrapper'

export const AdminProfilesList: React.FC = () => {
  const { admin } = useOrganizationContext()

  const {
    isLoading,
    isError,
    data: profiles,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery(
    'admin-profiles',
    ({ pageParam = { offset: 0, limit: LIMIT } }) =>
      fetchProfiles(admin.entity_id, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === LIMIT) {
          return { offset: pages.length * LIMIT, limit: LIMIT }
        }
        return undefined
      },
    },
  )

  useErrorToast(isError)

  const columns = [
    {
      title: 'Name',
      render: (p: Profile) => (
        <Link to={`/profiles/${p.profile_id}`}>
          {getFullName(p) || p.profile_id}
          {' [★]'}
        </Link>
      ),
    },
  ]

  const dataSource = profiles?.pages.reduce(
    (acc, curr) => [...acc, ...curr.data],
    [] as Profile[],
  )

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="profile_id"
        loading={isLoading}
        pagination={false}
      />
      {hasNextPage && (
        <Button
          style={{ width: '100%' }}
          size="large"
          loading={isFetching}
          onClick={() => fetchNextPage()}
        >
          Load More
        </Button>
      )}
    </>
  )
}
