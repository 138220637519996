import { useFeature } from '@optimizely/react-sdk'
import { Descriptions, Skeleton, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Screen } from '../components/Screen'
import { fetchProfile } from '../data/endpoints/ums'
import { CredentialTable } from '../screen-components/CredentialTable'
import { TransactionTable } from '../screen-components/TransactionTable'
import { mixPanelTrack } from '../utils'
import { getFullName } from '../utils-hooks/profile-utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'

export const ProfileScreen: React.FC = () => {
  const navigate = useNavigate()
  const goToList = () => navigate('/profiles')

  const params = useParams()
  const { id } = params

  const { isLoading, isError, data } = useQuery(['/profiles', id], () =>
    fetchProfile(id!),
  )
  useErrorToast(isError)

  const profile = data?.data

  const flags = {
    credentials: useFeature('express_credentials')[0],
    transactions: useFeature('express_transactions')[0],
  }

  useEffect(() => {
    mixPanelTrack('View Profile')
  }, [])

  return (
    <Screen
      pageHeader={{
        title: getFullName(profile),
        onBack: goToList,
      }}
    >
      {isLoading && (
        <>
          <Skeleton loading />
        </>
      )}

      {profile && (
        <Descriptions>
          <Descriptions.Item label="Email">
            {profile.emails.length > 0 ? profile.emails[0].email : '-'}
          </Descriptions.Item>
        </Descriptions>
      )}

      {flags.credentials && (
        <>
          <br />
          <Typography.Title level={4}>Credentials</Typography.Title>
          {profile && <CredentialTable profileId={id!} />}
        </>
      )}

      {flags.transactions && (
        <>
          <br />
          <Typography.Title level={4}>Transactions</Typography.Title>
          {profile && <TransactionTable profileId={id!} />}
        </>
      )}
    </Screen>
  )
}
