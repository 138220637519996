export type Session = {
  access_token: string
  refresh_token: string
}

export type Entity = {
  entity_id: string
  overseer_id: string
  entity_name: string
  entity_type_id: string
}

export type Profile = {
  profile_id: string
  overseer_id: string
  first_name: string
  middle_name: string
  last_name: string
  emails: {
    email: string
  }[]
}

export type Profile_Read = {
  profile_id: string
  person_id: string
  overseer_id: string
  first_name: string
  middle_name: string
  last_name: string
  entity_name: string
  avatar: string
  emails: {
    email: string
  }[]
  created_at: number
  organization_id: string
}

export type Credential = {
  credential_id: string
  overseer_id: string
  credential_format_id: string
  credential_fields: {
    credential_field_format_id: string
    value: string | number | boolean
  }[]
}

export type Credential_Read = {
  credential_id: string
  activate: string
  deactivate: string
  is_active: boolean
  credential_format_id: string
  credential_fields: {
    credential_field_format_id: string
    value: string | number | boolean
  }[]
  overseer_id: string
  entity_name: string
  entity_description: string
  entity_type_id: string
  entity_status: string
  extra_s: null
  extra_i: null
  created_at: string
  updated_at: string
}

export type CredentialWithFields = Credential_Read & {
  fields: {
    facilityCode: number
    cardId: number
    status: CredentialStatus
  }
}

export type CredentialFormat_Read = {
  credential_format_id: string
  credential_field_formats: {
    credential_field_format_id: string
    field_name: string
    field_type: string
    constraint: {
      type: string
      value: {
        valid_values: {
          type: string
          value: string | number | boolean
        }[]
      }
    }
  }[]
  overseer_id: string
  entity_name: string
  entity_description: string
  entity_type_id: string
  entity_status: string
  extra_s: null
  extra_i: null
  created_at: string
  updated_at: string
}

export const credentialStatuses = ['ACTIVE', 'SUSPENDED', 'REVOKED'] as const

export type CredentialStatus = (typeof credentialStatuses)[number]

export type TransactionType = 'DENIED' | 'GRANTED' | 'OTHER'

export type ACSTransaction = {
  acs_transaction_id: string
  epi_name: string
  event_at: string
  overseer_id: string
  result: TransactionType
}
