import { message } from 'antd'
import { useEffect } from 'react'

const DEFAULT_MESSAGE = "Something went wrong. Please try again."

export const useErrorToast = (error: string | boolean) => {
  return useEffect(() => {
    if (error) {
      const errorMessage = typeof error === 'string' ? error : DEFAULT_MESSAGE
      message.error(errorMessage)
    }
  }, [error])
}
