import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Screen } from '../components/Screen'
import { useUserContext } from '../states/UserContextProvider'
import { AdminProfilesList } from '../screen-components/AdminProfilesList'
import { GuestProfilesList } from '../screen-components/GuestProfilesList'

export const LIMIT = 50

enum Tab {
  Guests = 'guests',
  Admins = 'admins',
}

const tabItems = [
  { label: 'Guests', key: Tab.Guests, children: <GuestProfilesList /> },
  { label: 'Admins', key: Tab.Admins, children: <AdminProfilesList /> },
]

export const ProfileListScreenWrapper = () => {
  const navigate = useNavigate()
  const goToCreate = () => navigate('/profiles/create')

  const { signOut } = useUserContext()

  return (
    <Screen
      pageHeader={{
        title: 'Profiles',
        extra: [
          <Button key="invite" type="primary" role="link" onClick={goToCreate}>
            Invite
          </Button>,
          <Dropdown
            key="more"
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key="sign out" onClick={signOut}>
                  Sign Out
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                padding: 0,
              }}
            >
              <EllipsisOutlined
                style={{
                  fontSize: 20,
                  verticalAlign: 'top',
                }}
              />
            </Button>
          </Dropdown>,
        ],
      }}
    >
      <Tabs items={tabItems} />
    </Screen>
  )
}
