import 'antd/dist/antd.css'
import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { CreateProfileScreen } from './screens/CreateProfileScreen'
import { NotFoundScreen } from './screens/NotFoundScreen'
import { ProfileListScreenWrapper } from './screens/ProfilesListScreenWrapper'
import { ProfileScreen } from './screens/ProfileScreen'
import { OptimizelyProvider } from './states/OptimizelyProvider'
import { OrganizationContextProvider } from './states/OrganizationContextProvider'
import { UserContextProvider } from './states/UserContextProvider'
import { initMixpanel, mixPanelRegister, mixPanelReset } from './utils'

initMixpanel()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export const App: React.FC = () => {
  useEffect(() => {
    mixPanelReset()
    mixPanelRegister({
      'App Version': process.env.REACT_APP_VERSION,
      App: 'Express App',
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <OrganizationContextProvider>
          <OptimizelyProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </OptimizelyProvider>
        </OrganizationContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
}

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileListScreenWrapper />} />

      <Route path="/profiles" element={<ProfileListScreenWrapper />} />
      <Route path="/profiles/create" element={<CreateProfileScreen />} />
      <Route path="/profiles/:id" element={<ProfileScreen />} />

      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}
