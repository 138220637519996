import { Tag, TagProps } from 'antd'
import React from 'react'
import { CredentialStatus } from '../data/models'

type Props = {
  status: CredentialStatus
}

const colorMap: Record<CredentialStatus, TagProps['color']> = {
  ACTIVE: 'green',
  SUSPENDED: 'blue',
  REVOKED: 'default',
}

export const CredentialStatusTag: React.FC<Props> = ({ status }) => {
  const color = colorMap[status]

  return <Tag color={color}>{status}</Tag>
}
