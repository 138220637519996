import { Spin } from 'antd'
import { createContext, useContext, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { signOut } from '../data/endpoints/auth'
import { fetchProfileSelf } from '../data/endpoints/ums'
import { Profile_Read } from '../data/models'
import { LoginScreen } from '../screens/LoginScreen.tsx'
import { mixPanelTrack } from '../utils'

type Context = {
  user: Profile_Read
  signOut: () => void
}

// Short-circuit ahead, so this will never be used
const defaultValue = {} as Context

export const UserContext = createContext<Context>(defaultValue)

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider: React.FC = ({ children }) => {
  const [n, setN] = useState(0)
  const reload = () => setN((curr) => curr + 1)

  const { isLoading, data } = useQuery(['profiles/self', n], fetchProfileSelf)
  const user = data?.data

  const queryClient = useQueryClient()

  const clearSession = async () => {
    await signOut()
    queryClient.clear()
    mixPanelTrack('Sign Out')
    reload()
  }

  if (isLoading) {
    return <Spin />
  }

  if (!user) {
    return <LoginScreen onSignIn={reload} />
  }

  const context = { user, signOut: clearSession }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
