import axios from 'axios'
import { config } from '../config/config'

const { baseURL, version } = config.swiftConnectApi

export const client = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: `application/vnd.swiftconnect.v${version}+json`,
  },
})
