type Config = typeof devConfig

// Development
const devConfig = {
  swiftConnectApi: {
    baseURL: 'https://api.dev.swiftconnect.io',
    version: '2',
  },
  optimizely: {
    SDK_KEY: 'LAeaVzrkjCKEoz37EkLeS', // Development env
  },
}

// Production
const prodConfig: Config = {
  swiftConnectApi: {
    baseURL: 'https://api.swiftconnect.io',
    version: '2',
  },
  optimizely: {
    SDK_KEY: 'Xd73TtrdCtV6BEELKtNqR', // Demo env
  },
}

// -----

const getConfigFor = (env?: string) => {
  switch (env) {
    case 'development':
      return devConfig
    case 'production':
      return prodConfig
    default:
      console.warn(`Unknown environment "${env}". Defaulting to "development".`)
      return devConfig
  }
}

const env = process.env.REACT_APP_ENV
const config = getConfigFor(env)

export { config }
