import { Spin } from 'antd'
import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { fetchOrganizations } from '../data/endpoints/ums'
import { Entity } from '../data/models'
import { useUserContext } from './UserContextProvider'

export type Organizations = {
  top: Entity
  parent: Entity
  admin: Entity
  demo: Entity
}

// Short-circuit ahead, so this will never be used
const defaultValue = {} as Organizations

export const OrganizationContext = createContext<Organizations>(defaultValue)

export const useOrganizationContext = () => useContext(OrganizationContext)

export const OrganizationContextProvider: React.FC = ({ children }) => {
  const { user } = useUserContext()

  const { isLoading, data: organizations } = useQuery(
    'entities/demo-organizations',
    () => fetchOrganizations(user),
  )

  if (isLoading) {
    return <Spin />
  }

  if (!organizations) {
    console.error('Could not fetch organizations')
    return null
  }

  return (
    <>
      {user && (
        <OrganizationContext.Provider value={{ ...organizations }}>
          {children}
        </OrganizationContext.Provider>
      )}
    </>
  )
}
