import { Organizations } from '../../states/OrganizationContextProvider'
import { client } from '../client'
import { Entity, Profile, Profile_Read } from '../models'

// -----

const fetchRoles = async (baseId: string) => {
  return client.get<Entity[]>('/entities', {
    params: {
      'base-id': baseId,
      limit: 100,
      filters: 'entity_type_id EQ 15',
    },
  })
}

// -----

export const fetchEntity = async (id: string) => {
  return client.get<Entity>(`/entities/${id}`)
}

// -----

export const fetchOrganizations = async (profile: Profile_Read) => {
  const { data: admin } = await fetchEntity(profile.overseer_id)
  const { data: parent } = await fetchEntity(admin.overseer_id)
  const { data: top } = await fetchEntity(parent.overseer_id)
  const { data: cousins } = await fetchRoles(parent.entity_id)

  const demo = cousins.find(
    (entity) =>
      entity.entity_name.includes(' Demo User Role') ||
      entity.entity_name === 'User',
  )

  if (!admin || !parent || !top || !cousins || !demo) {
    console.warn("Couldn't fetch one of the required organizations")
  }

  return {
    top,
    parent,
    admin,
    demo: demo!,
  }
}

// -----

export const fetchProfiles = async (
  baseId: string,
  params?: { offset: number; limit: number },
) => {
  return client.get<Profile[]>('/profiles', {
    params: {
      'base-id': baseId,
      select: 'profile_id,person_id,first_name,last_name,emails',
      sort: 'created_at:desc',
      limit: params?.limit || 200,
      offset: params?.offset,
    },
  })
}

// -----

export const fetchProfile = async (id: string) => {
  return client.get<Profile_Read>(`/profiles/${id}`)
}

export const fetchProfileByEmail = async (entity: Entity, email: string) => {
  const { data: profiles } = await fetchProfiles(entity.entity_id)

  const match = profiles.find((p) => p.emails.find((e) => e.email === email))

  if (match) {
    const { data: profile } = await fetchProfile(match?.profile_id)
    return profile
  }
}

// -----

export const fetchProfileSelf = async () => {
  return client.get<Profile_Read>(`/profiles/self`)
}

// -----

type PostProfile = {
  first_name: string
  last_name: string
  email: string
  organization_id: string
}

export const createProfileIfDoesntExist = async (args: {
  organizations: Organizations
  profile: PostProfile
}) => {
  const { organizations, profile } = args
  const { top, demo } = organizations

  console.log('Verifying if profile exists...')

  const matchingProfile = await fetchProfileByEmail(top, profile.email)

  if (matchingProfile) {
    const isOtherStructure = matchingProfile.overseer_id !== demo.entity_id

    if (isOtherStructure) {
      console.log('Profile already exists in other structure; stopping.')
      return false
    } else {
      console.log('Profile already exists; updating instead...')
      return putProfile({
        ...matchingProfile,
        ...profile,
      })
    }
  } else {
    console.log('Profile does not exist; creating...')
    return postProfile(demo.entity_id, profile)
  }
}

const putProfile = async (profile: Profile_Read) => {
  return client.put<Profile_Read>(`/profiles/${profile.profile_id}`, {
    ...profile,
    entity_description: Date.now().toString(), // Workaround since BE won't send an email if nothing changes in the object
  })
}

const postProfile = async (entityId: string, profile: PostProfile) => {
  const { first_name, last_name, email, organization_id } = profile

  const payload = {
    overseer_id: entityId,
    first_name,
    last_name,
    organization_id,
    emails: [
      {
        email,
        status: 'CONFIRMED',
      },
    ],
  }

  return client.post<Profile_Read>('/profiles', payload)
}
