import {
  createInstance,
  OptimizelyProvider as _OptimizelyProvider,
} from '@optimizely/react-sdk'
import { minutesToMilliseconds } from 'date-fns'
import React from 'react'
import { config } from '../config/config'

// Configs
const { SDK_KEY } = config.optimizely
const POLLING_INTERVAL_MS = minutesToMilliseconds(5)
const PROXY_URL = 'https://cdn.fe.dev.swiftconnect.io/%s'

const id = '1' // Hardcoded on purpose; we don't want user segmentation

const optimizely = createInstance({
  sdkKey: SDK_KEY,
  datafileOptions: {
    // Tell the SDK to keep polling for changes
    // Note: doesn't re-render by default, the `useFeature` will still need to specify autoUpdate if wanted
    autoUpdate: true,
    updateInterval: POLLING_INTERVAL_MS,
    urlTemplate: PROXY_URL,
  },
  logLevel: 'warn',
})

export const OptimizelyProvider: React.FC = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_OptimizelyProvider optimizely={optimizely} user={{ id }}>
      {children}
    </_OptimizelyProvider>
  )
}
