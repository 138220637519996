import mixpanel from 'mixpanel-browser'
import { config } from '../config'
import { mapToMixpanel } from './mapToMixpanel'

const {
  mixpanel: { token, proxyUrl: api_host },
} = config

export type MixPanelTrackKey =
  | 'Sign In (Success)'
  | 'Sign In (Failure)'
  | 'Sign Out'
  | 'View Profile'
  | 'Send Invitation (Start)'
  | 'Send Invitation (Success)'
  | 'Send Invitation (Failure)'

export interface MixPanelTrackSuperProperties {
  'App Version': string
  App: string
}

export const initMixpanel = () =>
  mixpanel.init(token!, {
    debug: process.env.NODE_ENV === 'development',
    api_host,
  })

export const identifyMixpanel = (id: string) => {
  const uuid = mapToMixpanel('mixpanel', id)
  mixpanel.identify(uuid)
}

export const mixPanelTrack = (key: MixPanelTrackKey) => mixpanel.track(key)

export const mixPanelRegister = (
  properties: Partial<MixPanelTrackSuperProperties>,
) => mixpanel.register(properties)

export const mixPanelReset = () => mixpanel.reset()
