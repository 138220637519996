import { Tag, TagProps } from 'antd'
import React from 'react'
import { TransactionType } from '../data/models'

type Props = {
  type: TransactionType
}

const colorMap: Record<TransactionType, TagProps['color']> = {
  GRANTED: 'green',
  DENIED: 'red',
  OTHER: 'yellow',
}

export const TransactionTypeTag: React.FC<Props> = ({ type }) => {
  const color = colorMap[type]

  return <Tag color={color}>{type}</Tag>
}
