import { client } from '../client'
import { Session } from '../models'

type Credentials = {
  email: string
  password: string
}

export const signIn = async (credentials: Credentials) => {
  const payload = {
    login_type: 'email_password',
    ...credentials,
  }

  return client.post<Session>('/sessions', payload)
}

export const signOut = async () => {
  return client.delete('/sessions')
}
