import { Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Screen } from '../components/Screen'
import { createProfileIfDoesntExist } from '../data/endpoints/ums'
import { useOrganizationContext } from '../states/OrganizationContextProvider'
import { mixPanelTrack } from '../utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'
import { useUserContext } from '../states/UserContextProvider'

export const CreateProfileScreen: React.FC = () => {
  const navigate = useNavigate()
  const goToList = () => navigate('/profiles')

  const organizations = useOrganizationContext()
  const { user } = useUserContext()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const queryClient = useQueryClient()
  const { isLoading, isError, mutate } = useMutation(
    createProfileIfDoesntExist,
    {
      onSuccess: (res) => {
        if (res === false) {
          message.warn('Email is already in use.')
        } else if (res.status === 200) {
          queryClient.invalidateQueries('admin-profiles')
          queryClient.invalidateQueries('guest-profiles')
          message.success('Profile was created.')
          mixPanelTrack('Send Invitation (Success)')
          navigate(`/profiles/${res.data.profile_id}`)
        }
      },
      onError: (err) => {
        mixPanelTrack('Send Invitation (Failure)')
      },
    },
  )

  useErrorToast(isError)

  useEffect(() => {
    mixPanelTrack('Send Invitation (Start)')
  }, [])

  const validateAndSubmit = () => {
    const isValid = validate()
    if (isValid) {
      submit()
    }
  }

  const validate = () => {
    const hasMissingFields = [firstName.trim(), email.trim()].includes('')

    if (hasMissingFields) {
      message.warn('Fill in all required fields.')
    }

    return !hasMissingFields
  }

  const submit = () => {
    mutate({
      organizations,
      profile: {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        organization_id: user.organization_id,
      },
    })
  }

  return (
    <Screen
      pageHeader={{
        title: 'New Invitation',
        onBack: goToList,
      }}
    >
      <Form layout="vertical">
        <Form.Item label="First Name" required>
          <Input
            size="large"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Last Name">
          <Input
            size="large"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Email" required>
          <Input
            type="email"
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            onClick={validateAndSubmit}
            loading={isLoading}
          >
            Send Invitation
          </Button>
        </Form.Item>
      </Form>
    </Screen>
  )
}
