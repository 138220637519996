import {
  CredentialFormat_Read,
  CredentialStatus,
  credentialStatuses,
  Credential_Read,
} from '../data/models'

type CredentialField = Credential_Read['credential_fields'][number]

const isStatusField = (f: CredentialField) => {
  return credentialStatuses.includes(f.value as any)
}

export const getFieldValue = (
  credential: Credential_Read,
  format: CredentialFormat_Read,
  fieldName: string,
) => {
  const fieldTemplates = format.credential_field_formats
  const fields = credential.credential_fields

  // Get field ID from template
  const fieldTemplate = fieldTemplates.find((f) => f.field_name === fieldName)
  const fieldId = fieldTemplate?.credential_field_format_id

  // Get actual value from credential
  const field = fields.find((f) => f.credential_field_format_id === fieldId)

  return field?.value
}

export const createCredentialPayloadWithNewStatus = (
  credential: Credential_Read,
  newStatus: CredentialStatus,
): Credential_Read => {
  const fields = credential.credential_fields

  // Keep the non-status fields intact
  const otherFields = fields.filter((f) => !isStatusField(f))

  // Update the status field
  const statusField = fields.find(isStatusField)
  const newStatusField = {
    credential_field_format_id: statusField?.credential_field_format_id || '',
    value: newStatus as string,
  }

  // Put it all together
  const payload: Credential_Read = {
    ...credential,
    credential_fields: [...otherFields, newStatusField],
  }

  console.log('Other fields:', otherFields)
  console.log('Status field:', newStatusField)
  console.log('Payloadl:', payload)

  return payload
}
