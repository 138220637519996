import { Card, Spin } from 'antd'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { CredentialStatusTag } from '../components/CredentialStatusTag'
import {
  fetchCredentials,
  fetchCredentialWithFields,
} from '../data/endpoints/acs'
import { Credential, CredentialWithFields } from '../data/models'
import { useErrorToast } from '../utils-hooks/use-error-toast'
import { CredentialModal } from './CredentialModal'

type Props = {
  profileId: string
}

export const CredentialTable: React.FC<Props> = ({ profileId }) => {
  const { isLoading, isError, data } = useQuery(
    ['/credentials/profile', profileId],
    () => fetchCredentials(profileId),
  )
  useErrorToast(isError)

  const credentials = data?.data

  return (
    <>
      {isLoading && <Spin />}

      {credentials?.length === 0 && <p>No credentials.</p>}

      {credentials &&
        credentials.map((c) => (
          <CredentialRow key={c.credential_id} credential={c} />
        ))}
    </>
  )
}

type CredentialRowProps = {
  credential: Credential
}

export const CredentialRow: React.FC<CredentialRowProps> = ({ credential }) => {
  const [activeCredential, setActiveCredential] =
    useState<CredentialWithFields>()
  const clearActiveCredential = () => setActiveCredential(undefined)

  const {
    isLoading,
    isError,
    data: fullCredential,
  } = useQuery(['/credentials', credential.credential_id], () =>
    fetchCredentialWithFields(credential),
  )
  useErrorToast(isError)

  if (isLoading) {
    return <Spin />
  }

  const { facilityCode, cardId, status } = fullCredential!.fields

  const displayName = [facilityCode, cardId].join('-')

  return (
    <>
      {activeCredential && (
        <CredentialModal
          credential={activeCredential}
          visible={true}
          onClose={clearActiveCredential}
          onStatusUpdated={clearActiveCredential}
        />
      )}

      <Card onClick={() => setActiveCredential(fullCredential)}>
        <div style={{ display: 'flex' }}>
          <strong style={{ flex: 1 }}>{displayName}</strong>
          <CredentialStatusTag status={status} />
        </div>
      </Card>
    </>
  )
}
