import { message, Modal, ModalProps, Select } from 'antd'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { putCredential } from '../data/endpoints/acs'
import {
  CredentialStatus,
  credentialStatuses,
  CredentialWithFields,
} from '../data/models'
import { createCredentialPayloadWithNewStatus } from '../utils-hooks/credential-utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'

const options = credentialStatuses.map((s) => ({
  title: s,
  value: s,
}))

type Props = ModalProps & {
  credential: CredentialWithFields
  onStatusUpdated: () => void
  onClose: () => void
}

export const CredentialModal: React.FC<Props> = ({
  credential,
  onStatusUpdated,
  onClose,
  ...rest
}) => {
  const { fields } = credential

  const [status, setStatus] = useState<CredentialStatus>(fields.status)

  const queryClient = useQueryClient()
  const { isLoading, isError, mutate } = useMutation(putCredential, {
    onSuccess: (res) => {
      if (res.status === 200) {
        queryClient.invalidateQueries([
          '/credentials',
          credential.credential_id,
        ])
        message.success('Status was updated.')
        onStatusUpdated()
      }
    },
  })
  useErrorToast(isError)

  const submit = () => {
    const payload = createCredentialPayloadWithNewStatus(credential, status)

    mutate(payload)
  }

  return (
    <Modal
      title="Update Status"
      {...rest}
      onCancel={onClose}
      onOk={submit}
      okButtonProps={{ loading: isLoading }}
    >
      <Select
        style={{ width: '100%' }}
        options={options}
        value={status}
        onChange={setStatus}
      />
    </Modal>
  )
}
