import { Layout, PageHeader, PageHeaderProps } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { themeConfig } from '../config/theme-config'

const { padding } = themeConfig

type Props = {
  pageHeader: PageHeaderProps
}

export const Screen: React.FC<Props> = ({ pageHeader, children }) => {
  return (
    <Layout>
      <Content>
        <PageHeader
          {...pageHeader}
          style={{
            minHeight: 56,
            paddingLeft: padding.m,
            paddingRight: padding.m,
            paddingTop: padding.s,
            paddingBottom: padding.s,
          }}
        />
        <div style={{ padding: padding.m }}>{children}</div>
      </Content>
    </Layout>
  )
}
