import { Button, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Screen } from '../components/Screen'
import { signIn } from '../data/endpoints/auth'
import { Session } from '../data/models'
import { mixPanelTrack } from '../utils'
import { useErrorToast } from '../utils-hooks/use-error-toast'

type Props = {
  onSignIn: (session: Session) => void
}

export const LoginScreen: React.FC<Props> = ({ onSignIn }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isLoading, isError, mutate } = useMutation(signIn, {
    onSuccess: (res) => {
      if (res.status === 200) {
        mixPanelTrack('Sign In (Success)')
        onSignIn(res.data)
      }
    },
    onError: (err) => {
      mixPanelTrack('Sign In (Failure)')
    },
  })

  useErrorToast(isError)

  const validateAndSubmit = () => {
    const isValid = validate()
    if (isValid) {
      submit()
    }
  }

  const validate = () => {
    const hasMissingFields = [email, password].includes('')

    if (hasMissingFields) {
      message.warn('Fill in all required fields.')
    }

    return !hasMissingFields
  }

  const submit = () => {
    mutate({
      email,
      password,
    })
  }

  return (
    <Screen
      pageHeader={{
        title: 'Sign In',
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Email">
          <Input
            type="email"
            placeholder="you@domain.com"
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Password">
          <Input
            type="password"
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            onClick={validateAndSubmit}
            loading={isLoading}
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </Screen>
  )
}
